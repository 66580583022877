<template>
    <section id="agentResultTab">
        <div class="container">

            <div class="total_reward_claimed" v-if="Object.keys(totalRewardApiData).length > 0">
                <div v-if="totalRewardApiData.isClaimable == true && totalRewardApiData.totalPendingPrize > 0 && (previousEventStatus == 'status4' || previousEventStatus == 'status5' || eventStatus == 'status4' || annualEventStatus == 'status4')"
                    class="pending_claim">
                    <img :src="imageUrl + 'bg_pending_claim_reward.png'">
                    <p>{{ $t('ib6.rewardClaim.title1') }} <span>USD {{ totalRewardApiData.totalPendingPrizeText }}</span> {{
                        $t('ib6.rewardClaim.title2') }}</p>
                </div>
                <div v-if="totalRewardApiData.totalPrizeClaimed >= 0 && (previousEventStatus == 'status4' || previousEventStatus == 'status5' || eventStatus == 'status4' || annualEventStatus == 'status4')"
                    id="
                History_1" class="wrapper">
                    <img class="bg_top_lef" :src="imageUrl + 'coin_top_left.png'">
                    <img class="bg_top_right" :src="imageUrl + 'coin_top_right.png'">
                    <img class="bg_bottom_lef" :src="imageUrl + 'coin_bottom_left.png'">
                    <p>{{ $t('ib6.rewardClaim.subTitle') }}<span class="content">USD {{
                        totalRewardApiData.totalPrizeClaimedText }}</span>
                        <a :href="`${this.$i18n.locale}/claimedHistory`">
                            <div class="bg_arrow"><i class="arrow_right"></i></div>
                        </a>
                    </p>
                </div>
                <a v-if="totalRewardApiData.isClaimable == true && totalRewardApiData.totalPendingPrize > 0 && (previousEventStatus == 'status4' || previousEventStatus == 'status5' || eventStatus == 'status4' || annualEventStatus == 'status4')"
                    id="Redeem_1" class="btnEventInfo" @click="openModal"><span class="btnInfoTitle">{{
                        $t('ib6.rewardClaim.btnTitle')
                    }}</span></a>
            </div>

            <div class="tab">
                <a @click="activeTab(0); setGTMEvent(`${commonStore.currentEvent}_levelstatus_logged`)"
                    :id="`${commonStore.currentEvent}_levelstatus_logged`" class="tablinks active">{{
                        $t('ib6.monthlyStatistics.tabTitle1') }}</a>
                <a @click="activeTab(1); setGTMEvent(`${commonStore.currentEvent}_annualstatus_logged`)"
                    :id="`${commonStore.currentEvent}_annualstatus_logged`" class="tablinks ">{{
                        $t('ib6.monthlyStatistics.tabTitle2') }}</a>
            </div>
            <PassResultFloatingIcon v-if="Object.keys(previousResultApiData).length > 0" />
            <div v-if="selected_tab == 0" class="tab_content active">
                <PublishResultTab v-if="Object.keys(resultApiData).length > 0" :eventStatus="eventStatus"
                    :dateToCount="dateToCount" :resultApiData="resultApiData" :previousEventStatus="previousEventStatus" />
            </div>
            <div v-if="selected_tab == 1" class="tab_content active">
                <PublishAnnualResultTab v-if="Object.keys(annualResultApiData).length > 0" :eventStatus="annualEventStatus"
                    :dateToCount="annualDateToCount" :annualResultApiData="annualResultApiData" />
            </div>
        </div>
    </section>
</template>

<script lang="ts">
import { Gift, GoldInfo, GoldPrize, PrizeInfo, ResultData, ResultRoot } from "@/modal/Result";
import PublishResultTab from "@/views/ib6/PublishResultTab.vue";
import PublishAnnualResultTab from "@/views/ib6/PublishAnnualResultTab.vue";
import { getPublishResults, getAnnualReward, getAchievedPrizeDetails } from "@/api/getResult";
import { AnnualRewardRoot, AnnualRewardData, AnnualPrizeVo, AnnualGift, AnnualPrizeInfo } from "@/modal/AnnualReward";
import { formatDate, numberWithCommas, padNumberWithZeros } from "@/service/general";
import EventInfoBtn from "@/components/button/EventInfoBtn.vue";
import { activityTime } from '@/api/ib4-0'
import { TotalRewardData, TotalRewardRoot } from "@/modal/TotalReward";
import { useCommonStore } from "@/stores/common";
import { setGTMEvent } from "@/service/gtm";
import PassResultFloatingIcon from "@/components/FloatingIcons/PassResultFloatingIcon.vue";


export default {
    props: {
        totalRewardApiData: {
            default: {} as TotalRewardData,
        },
        resultApiData: {
            default: {} as ResultData,
        },
        annualResultApiData: {
            default: {} as AnnualRewardData,
        },
        previousResultApiData: {
            default: {} as ResultData,
        },
        eventStatus: {
            default: ''
        },
        annualEventStatus: {
            default: ''
        },
        previousEventStatus: {
            default: ''
        },
        dateToCount: {
            default: ''
        },
        annualDateToCount: {
            default: ''
        },
        publishAnnualResultFunction: {},
        publishResultFunction: {},
    },
    components: {
        PublishResultTab,
        PublishAnnualResultTab,
        EventInfoBtn,
        PassResultFloatingIcon,
    },
    data() {
        return {
            imageUrl: "https://doo-prime-static.oss-cn-hongkong.aliyuncs.com/ib_event/ib6/images/",
            commonStore: useCommonStore(),
            selected_tab: 0,
        }
    },
    methods: {
        setGTMEvent,
        activeTab(id: number) {
            if (this.selected_tab != id) {
                let head = document.querySelectorAll<HTMLElement>('.tablinks');

                for (let index = 0; index < head.length; index++) {
                    const element = head[index];
                    if (index == id) {
                        element.classList.toggle("active");
                    } else {
                        element.classList.remove("active");
                    }
                }
            }

            this.selected_tab = id

            if (id == 1) {
                this.publishAnnualResultFunction();
            } else {
                this.publishResultFunction();
            }
        },

        openModal() {
            this.commonStore.openModal('RewardList')
        },

    },
    mounted() {
        // this.timerInit()
    }
}
</script>

<style lang="scss">
#agentResultTab {

    p {
        margin: 0;
    }

    .total_reward_claimed {

        .bg_top_lef {
            max-width: 55px;
            position: absolute;
            translate: 50% 50%;
            object-fit: contain;
            top: -64px;
            left: -42px;
            z-index: 2;

            &.bg_top_lef.deactivate {
                top: -64px;
            }
        }

        .bg_top_right {
            max-width: 30px;
            position: absolute;
            translate: 50% 50%;
            object-fit: contain;
            top: -33px;
            right: 15px;
            z-index: 2;

            &.bg_top_right.deactivate {
                top: -34px;
            }
        }

        .bg_bottom_lef {
            max-width: 41px;
            position: absolute;
            translate: 50% 50%;
            object-fit: contain;
            top: 27px;
            left: 8px;
            z-index: 2;

            &.bg_bottom_lef.deactivate {
                top: 28px;
            }
        }

        // @media screen and (min-width:600px) {
        //     .bg_top_lef {
        //         top: 16px;
        //     }

        //     .bg_top_right {
        //         top: 47px;
        //         right: 48px;
        //     }

        //     .bg_bottom_lef {
        //         top: 108px;
        //         left: 48px;
        //     }

        // }

        .pending_claim {
            position: relative;
            // margin-bottom: -10px;
            margin: auto 70px -5px;
            max-height: 70px;


            img {
                // max-width: 240px;
                // width: 100%;
                // margin: auto;
                height: 100%;
                position: relative;
                object-fit: fill;
            }

            p {
                width: 100%;
                position: absolute;
                // top: 8px;
                // left: calc(50% - 120px);
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                // translate: -50% -50%;
                font-size: 12px;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.67;
                letter-spacing: normal;
                text-align: center;
                color: #3a424b;

                span {
                    font-size: 16px;
                    font-weight: bold;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: 1.25;
                    letter-spacing: normal;
                    text-align: left;
                    color: #d91d22;
                }
            }

            @media screen and (min-width:600px) {
                p {
                    top: 60%
                }
            }
        }

        .wrapper {
            position: relative;
            padding: 20px 12px;
            margin: 0 24px 24px;
            border-radius: 50px;
            background-color: #d91d22;
            box-shadow:
                0 0 0 5px #fff4e1,
                0 0 0 8px #fff,
                0 0 0 10px #f5d7ca;

            p {
                // margin: 0 9px;
                display: flex;
                justify-content: space-between;
                align-items: center;
                font-size: 12px;
                font-weight: 600;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.25;
                letter-spacing: normal;
                text-align: left;
                color: #ffe8bf;

                .content {
                    font-size: 24px;
                    font-weight: bold;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: 0.83;
                    letter-spacing: normal;
                    text-align: left;
                    color: #fff;
                }

                .bg_arrow {
                    display: inline-block;
                    width: 24px;
                    height: 24px;
                    border-radius: 50%;
                    background-color: rgba(255, 255, 255, 0.378);

                    .arrow_right {
                        margin: 8px 6px 0;
                        border: solid #fff;
                        border-width: 0 3px 3px 0;
                        display: inline-block;
                        padding: 3px;
                        transform: rotate(-45deg);
                        -webkit-transform: rotate(-45deg);
                    }

                }
            }

        }
    }

    /* Style the tab */
    .tab {
        margin-top: 48px;
        overflow: hidden;
        // border-radius: 12px;
        border-top-left-radius: 12px;
        border-top-right-radius: 12px;
        box-shadow: 0 2px 8px 0 rgba(151, 151, 151, 0.3);
        border: solid 1px #fff;
        background-image: linear-gradient(6deg, #e3cfa1 50%, #fff9eb 100%, #fff3d8 62%, #dfc894 5%);
        // background-image: linear-gradient(265deg, #e3cfa1 109%, #fff9eb 89%, #fff3d8 62%, #dfc894 6%);
        position: relative;
        z-index: 1;

        &:before {
            display: block;
            content: '';
            width: 100%;
            height: 100%;
            background: url('https://doo-prime-static.oss-cn-hongkong.aliyuncs.com/ib_event/ib6/images/assist_bg.png') no-repeat right;
            background-size: contain;
            position: absolute;
            z-index: -1;
        }

        a {
            font-family: AlibabaPuHuiTi;
            width: 50%;
            float: left;
            cursor: pointer;
            padding: 14px 16px 22px;
            transition: 0.3s;
            font-size: 20px;
            font-weight: 900;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            text-align: center;
            color: #c39654;
        }

        a.active {
            // background-color: #ccc;
            box-shadow: 2px 0 4px 0 rgba(0, 0, 0, 0.25);
            border-top-left-radius: 12px;
            border-top-right-radius: 12px;
            border: 1px solid;
            border-image-slice: 1;
            background-image: linear-gradient(to right, #da2328 0%, #eb5d61 100%);
            color: #fff;

        }
    }

    /* Style the tab content */
    .tab_content {
        margin-top: -8px;
        position: relative;
        z-index: 1;
        display: none;
        padding: 6px 16px 24px;
        border-radius: 12px;
        border: solid 1px #fff;
        background-color: #fff;

        &.active {
            display: block;
        }
    }



}
</style>