<template>
    <main>
        <Banner />
        <div class="cover"></div>
        <TotalRewardDescription v-if="agentStore.isLogin == false" />
        <PublishResult v-if="agentStore.isLogin == true" :totalRewardApiData="totalRewardApiData"
            :resultApiData="resultApiData" :annualResultApiData="annualResultApiData" :eventStatus="eventStatus"
            :annualEventStatus="annualEventStatus" :dateToCount="dateToCount" :annualDateToCount="annualDateToCount"
            :publishAnnualResultFunction="publishAnnualResult" :publishResultFunction="publishResult"
            :previousEventStatus="previousEventStatus" :previousResultApiData="previousResultApiData" />
        <!-- <PassResultFloatingIcon v-if="Object.keys(previousResultApiData).length > 0 && agentStore.isLogin == true" /> -->
        <RankBoard v-if="agentStore.isLogin == true && eventTypeList.length > 0" :eventTypeList="eventTypeList" />
        <!-- <EventBenefit v-if="agentStore.isLogin == true" :redPacketApiData="redPacketApiData"
            :checkRedPacketFunction="getRedPacket" /> -->
        <EventDescriptionTab v-if="agentStore.isLogin == false" />
        <whyDoo />
        <Footer />
        <Modals :totalRewardApiData="totalRewardApiData" :checkRedPacketFunction="getRedPacket"
            :getTotalRewardFunction="getTotalReward" :previousResultApiData="previousResultApiData" />
    </main>
</template>
<script lang="ts">
import Banner from '@/components/Header/Banner.vue'
import Modals from '@/components/Modals/ModalMain.vue'
import EventDescriptionTab from '@/views/ib6/EventDescriptionTab.vue';
import { useCommonStore } from '@/stores/common'
import { useAgentStore } from '@/stores/user'
import Footer from "@/components/Footer/Footer_cms.vue";
import { getCookie } from '@/service/cookie'
import { getLocation } from "@/api/location";
import { checkSession, logout, signIn } from '@/api/auth'
import TotalRewardDescription from "@/views/ib6/totalRewardDescription.vue";
import whyDoo from "@/components/whyDoo.vue";
import RankBoard from "@/views/ib6/RankBoard.vue";
import EventBenefit from "@/views/ib6/home_event/EventBenefit.vue";
// model
import PublishResult from "@/views/ib6/PublishResult.vue";
import { getRedPacketStatus } from "@/api/red";
import { RedPacketData, RedPacketRoot } from "@/modal/RedPacket";
import { ClaimTypeData, TotalRewardData, TotalRewardRoot } from "@/modal/TotalReward";
import { getAchievedPrizeDetails, getAnnualReward, getPublishResults } from "@/api/getResult";
import { numberWithCommas, padNumberWithZeros } from "@/service/general";
import { Gift, GoldInfo, GoldPrize, PrizeInfo, ResultData, ResultRoot } from "@/modal/Result";
import { AnnualGift, AnnualPrizeInfo, AnnualRewardData, AnnualRewardRoot } from "@/modal/AnnualReward";
import { activityTime } from "@/api/ib4-0";
import PassResultFloatingIcon from "@/components/FloatingIcons/PassResultFloatingIcon.vue";

export default {
    name: 'Home',
    components: {
        Banner,
        Modals,
        EventDescriptionTab,
        Footer,
        TotalRewardDescription,
        whyDoo,
        PublishResult,
        RankBoard,
        EventBenefit,
        PassResultFloatingIcon,
    },
    data() {
        return {
            imageUrl: "https://doo-prime-static.oss-cn-hongkong.aliyuncs.com/ib_event/ib6/images/",
            agentStore: useAgentStore(),
            commonStore: useCommonStore(),
            uuid: '',
            totalRewardApiData: {} as TotalRewardData,
            annualResultApiData: {} as AnnualRewardData,
            resultApiData: {} as ResultData,
            previousResultApiData: {} as ResultData,
            eventStatus: '',
            dateToCount: '',
            annualEventStatus: '',
            annualDateToCount: '',
            previousEventStatus: '',
            redPacketApiData: {} as RedPacketData,
            eventTypeList: [],
            latestEvent: '',
        }
    },
    methods: {
        async tokenApi() {
            let token: string = window.location.href.split("token=")[1];
            let validCountry: boolean = true;
            let defaultCountryCode = '';
            let countryCode = '';
            try {

                if (token) {
                    const auth = await signIn(token)
                    let session = auth.data.data
                    countryCode = getCookie("countryCode") || session.countryCode;
                    defaultCountryCode = session.defaultCountryCode;

                    this.uuid = session.uuid
                    validCountry = this.checkValidCountryCode(defaultCountryCode);
                } else {

                    const session = await checkSession()

                    // const session = await signIn('dev')
                    if (session.data.code === 0) {
                        this.uuid = session.data.data.uuid

                        countryCode = getCookie("countryCode") || session.data.data.countryCode;
                        defaultCountryCode = session.data.data.defaultCountryCode;

                        validCountry = this.checkValidCountryCode(defaultCountryCode);

                    }

                }
                if (this.uuid != '') {
                    this.agentStore.setIsLogin(true);
                    this.agentStore.setUuid(this.uuid);
                    this.getTotalReward();
                    this.publishResult();
                    // this.getRedPacket();
                }

                if (this.agentStore.isLogin == false) {
                    const location = await getLocation();

                    if (location.code == 0) {
                        defaultCountryCode = location.data.country_code
                    }
                }

                await this.commonStore.setAgentLocation(defaultCountryCode);

                if (validCountry === true && countryCode != '') {
                    this.$router.push(`/${countryCode}`)
                }
                else if (validCountry === false) {
                    this.commonStore.openModal('InvalidCountry')
                }

            } catch (error) {
                console.log(error);
            }
        },
        async getRedPacket() {
            const { data }: { data: RedPacketRoot } = await getRedPacketStatus();
            this.redPacketApiData = data.data;
            if (data.data.isFestive == true) {
                this.commonStore.openModal('RedPacketHoliday')
            } else if (data.data.isDob == true) {
                this.commonStore.openModal('RedPacketBirthday')
            } else {
                this.commonStore.closeModal()
            }
        },
        async getTotalReward() {
            const { data }: { data: TotalRewardRoot } = await getAchievedPrizeDetails();
            if (data.code == 0) {
                data.data.totalPrizeClaimedText = numberWithCommas(data.data.totalPrizeClaimed);
                data.data.totalPendingPrizeText = numberWithCommas(data.data.totalPendingPrize);
                this.commonStore.totalAmountClaimed = data.data.totalPrizeClaimedText;
                if (data.data.claimablePrize.length > 0) {
                    data.data.giftTypeKey = {} as ClaimTypeData;

                    for (let index = 0; index < data.data.claimablePrize.length; index++) {
                        const element = data.data.claimablePrize[index];
                        const tempData = {
                            giftType: element.activityType,
                            giftCode: element.prizeAmount,
                            giftName: element.prizeAmount,
                            eventType: element.eventType,
                        }
                        switch (element.activityType) {
                            case 0:
                                data.data.giftTypeKey.levelBonus = tempData
                                break;
                            case 1:
                                data.data.giftTypeKey.goldBonus = tempData
                                break;
                            case 2:
                                data.data.giftTypeKey.rankingGold = tempData
                                break;
                            case 3:
                                data.data.giftTypeKey.rankingVolume = tempData
                                break;
                            case 4:
                                data.data.giftTypeKey.rankingMIB = tempData
                                break;
                        }
                        element.prizeAmountText = numberWithCommas(element.prizeAmount);
                    }
                }

                this.totalRewardApiData = data.data;
            }
        },

        async publishAnnualResult() {
            const { data }: { data: AnnualRewardRoot } = await getAnnualReward();
            if (data.code == 0) {

                data.data.totalInGold = numberWithCommas(data.data.totalInGold);
                data.data.totalTransaVolume = numberWithCommas(data.data.totalTransaVolume);


                data.data.annualPrizeInfo = {} as AnnualPrizeInfo
                data.data.annualPrizeInfo.gifts = {} as AnnualGift

                if (Number(data.data.target) < 3 && this.annualEventStatus == 'status2') {
                    data.data.annualPrizeInfo.target = data.data.nextAnnualPrizeVo.target;
                    data.data.annualPrizeInfo.depositStandard = numberWithCommas(data.data.nextAnnualPrizeVo.depositStandard);
                    data.data.annualPrizeInfo.volumeStandard = numberWithCommas(data.data.nextAnnualPrizeVo.volumeStandard);
                    data.data.annualPrizeInfo.qualifiedCustomerNo = data.data.nextAnnualPrizeVo.qualifiedCustomerNo

                    data.data.annualPrizeInfo.gifts.giftName = numberWithCommas(data.data.nextAnnualPrizeVo.gifts[0].giftName);
                    data.data.annualPrizeInfo.gifts.giftCode = data.data.nextAnnualPrizeVo.gifts[0].giftCode;
                } else {
                    if (data.data.target == '0') {

                        data.data.annualPrizeInfo.target = data.data.nextAnnualPrizeVo.target;
                        data.data.annualPrizeInfo.depositStandard = numberWithCommas(data.data.nextAnnualPrizeVo.depositStandard);
                        data.data.annualPrizeInfo.volumeStandard = numberWithCommas(data.data.nextAnnualPrizeVo.volumeStandard);
                        data.data.annualPrizeInfo.qualifiedCustomerNo = data.data.nextAnnualPrizeVo.qualifiedCustomerNo
                        //
                        data.data.annualPrizeInfo.gifts.giftName = numberWithCommas(data.data.nextAnnualPrizeVo.gifts[0].giftName);
                        data.data.annualPrizeInfo.gifts.giftCode = data.data.nextAnnualPrizeVo.gifts[0].giftCode;
                    } else {
                        data.data.annualPrizeInfo.target = data.data.annualPrizeVo.target;
                        data.data.annualPrizeInfo.depositStandard = numberWithCommas(data.data.annualPrizeVo.depositStandard);
                        data.data.annualPrizeInfo.volumeStandard = numberWithCommas(data.data.annualPrizeVo.volumeStandard);
                        data.data.annualPrizeInfo.qualifiedCustomerNo = data.data.annualPrizeVo.qualifiedCustomerNo
                        //
                        data.data.annualPrizeInfo.gifts.giftName = numberWithCommas(data.data.annualPrizeVo.gifts[0].giftName);
                        data.data.annualPrizeInfo.gifts.giftCode = data.data.annualPrizeVo.gifts[0].giftCode;
                    }
                }
                if (Number(data.data.target) == 0) {
                    data.data.target = "1";
                }
                this.annualResultApiData = data.data;
            }
        },

        async publishResult() {
            const { data } = await getPublishResults();

            if (data.code == 0) {
                // console.log(data.data)
                for (const key in data.data) {
                    const temp = data.data[key][0];
                    //
                    temp.eventInGold = numberWithCommas(temp.eventInGold);
                    temp.tradingVolume = numberWithCommas(temp.tradingVolume);
                    temp.goldAmount = numberWithCommas(temp.goldAmount);
                    temp.totalCommission = numberWithCommas(temp.totalCommission);
                    //
                    temp.prizeInfo = {} as PrizeInfo
                    temp.prizeInfo.gifts = {} as Gift
                    temp.goldInfo = {} as GoldInfo
                    temp.goldInfo.goldPrize = {} as GoldPrize

                    if (temp.agentLevel < 9 && this.eventStatus == 'status2') {

                        temp.prizeInfo.depositStandard = numberWithCommas(temp.nextPrizeInfoLevelVo.depositStandard);
                        temp.prizeInfo.volumeStandard = numberWithCommas(temp.nextPrizeInfoLevelVo.volumeStandard);
                        temp.prizeInfo.gifts.giftName = numberWithCommas(temp.nextPrizeInfoLevelVo.gifts[0].giftName);
                        temp.prizeInfo.gifts.giftCode = temp.nextPrizeInfoLevelVo.gifts[0].giftCode;
                        // gold
                        if (temp.agentLevel > 0) {
                            temp.goldInfo.goldLot = numberWithCommas(temp.nextGoldPrizeLevelVo.goldLot);
                            temp.goldInfo.goldPrize.giftName = numberWithCommas(temp.nextGoldPrizeLevelVo.goldPrize[0].giftName);
                        }
                    } else {
                        //
                        if (temp.agentLevel == 0) {
                            temp.prizeInfo.depositStandard = numberWithCommas(temp.nextPrizeInfoLevelVo.depositStandard);
                            temp.prizeInfo.volumeStandard = numberWithCommas(temp.nextPrizeInfoLevelVo.volumeStandard);
                            //
                            temp.prizeInfo.gifts.giftName = numberWithCommas(temp.nextPrizeInfoLevelVo.gifts[0].giftName);
                            temp.prizeInfo.gifts.giftCode = temp.nextPrizeInfoLevelVo.gifts[0].giftCode;
                        } else {
                            temp.prizeInfo.depositStandard = numberWithCommas(temp.prizeInfoLevelVo.depositStandard);
                            temp.prizeInfo.volumeStandard = numberWithCommas(temp.prizeInfoLevelVo.volumeStandard);
                            //
                            temp.prizeInfo.gifts.giftName = numberWithCommas(temp.prizeInfoLevelVo.gifts[0].giftName);
                            temp.prizeInfo.gifts.giftCode = temp.prizeInfoLevelVo.gifts[0].giftCode;
                        }
                        if (temp.agentLevel > 1 && temp.agentGoldLevel > 1) {
                            temp.goldInfo.goldLot = numberWithCommas(temp.goldPrizeLevelVo.goldLot);
                            temp.goldInfo.goldPrize.giftName = numberWithCommas(temp.goldPrizeLevelVo.goldPrize[0].giftName);
                        } else {
                            temp.goldInfo.goldLot = numberWithCommas(temp.nextGoldPrizeLevelVo.goldLot);
                            temp.goldInfo.goldPrize.giftName = numberWithCommas(temp.nextGoldPrizeLevelVo.goldPrize[0].giftName);
                        }
                    }
                }

                if (this.eventStatus == 'status2' || this.eventStatus == 'status3' || this.eventStatus == 'status4' || this.eventStatus == 'status5') {
                    this.previousResultApiData = data.data[this.eventTypeList[0]][0];
                }

                this.resultApiData = data.data[this.latestEvent][0];

            }

        },

        async timerInit() {
            const { data } = await activityTime();
            this.eventTypeList = Object.keys(data.data)

            const latestEvent = this.eventTypeList[this.eventTypeList.length - 1];


            for (const key in data.data) {
                if (data.data[latestEvent][0].ongoingStatus != 'status1') {
                    this.latestEvent = this.eventTypeList[this.eventTypeList.length - 1];
                    this.previousEventStatus = data.data[this.eventTypeList[0]][0].ongoingStatus;
                } else {
                    this.latestEvent = this.eventTypeList[0];
                }
            }

            this.dateToCount = data.data[this.latestEvent][0].nextDate;
            this.eventStatus = data.data[this.latestEvent][0].ongoingStatus;
            this.annualEventStatus = data.data[this.latestEvent][0].annualOngoingStatus;
            this.annualDateToCount = data.data[this.latestEvent][0].annualNextDate;

        },

        checkValidCountryCode(data: string) {
            const validCountry = ['CN', 'TW', 'TC']
            if (validCountry.includes(data.toUpperCase())) {
                return true;
            } else {
                return false;
            }
        }
    },
    mounted() {
        this.tokenApi();
        this.timerInit();
    },
}
</script>
<style lang="scss" scoped>
$cdn: 'https://doo-prime-static.oss-cn-hongkong.aliyuncs.com/ib_event/ib6/images/';

main {
    background: url($cdn + "bg@2x.png") no-repeat center top;
    max-width: 600px;
    margin: 0 auto;
    position: relative;

    .cover {
        position: absolute;
        height: 93px;
        max-width: 600px;
        width: 100%;
        background-image: linear-gradient(to bottom, #fff 50%, rgba(255, 255, 255, 0) 90%);
    }
}

.child_bg {
    background: url($cdn + "child_bg.png") no-repeat center top;
    max-width: 600px;
    margin: 0 auto;
}
</style>
