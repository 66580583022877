<template>
    <div :id="`${commonStore.currentEvent}_lastresult_logged`" class="floating">
        <div class="container">
            <div>
                <a href="#rank">
                    <img class="ranking" :src="imageUrl + 'ranking.png'">
                    <p>{{ $t('ib6.floating.rankingTitle') }}</p>
                </a>
            </div>
            <div @click="commonStore.openModal('PassEventRecord')">
                <img class="result" :src="imageUrl + 'pass_result_icon.png'">
                <p>{{ $t('ib6.floating.title') }}</p>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { useCommonStore } from "@/stores/common";

export default {
    data() {
        return {
            imageUrl: "https://doo-prime-static.oss-cn-hongkong.aliyuncs.com/ib_event/ib6/images/",
            commonStore: useCommonStore(),

        }
    },
}
</script>

<style lang="scss" scoped>
.floating {
    height: 0px;
    // position: absolute;
    // z-index: 10000;
    // top: 20%;
    // right: 0;
    // translate: 50% 50%;
    margin-top: 20px;
    position: sticky;
    top: 10%;
    right: 10px;
    z-index: 1000;
    width: 100%;
    cursor: pointer;

    .container {
        display: flex;
        flex-direction: column;
        align-items: end;
        padding: 0;

        div {
            position: sticky;
            max-width: 90px;
            float: right;
            margin-bottom: 20px;

            // margin-top: 100px;
            .ranking {
                width: 34px;
                height: 34px;
                position: absolute;
                z-index: 1;
                top: -21px;
                left: 15%;
            }

            .result {
                width: 34px;
                height: 34px;
                position: absolute;
                z-index: 1;
                top: -21px;
                left: 25%;
            }

            p {
                font-size: 12px;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.67;
                letter-spacing: normal;
                text-align: center;
                color: #e48f2b;
                padding: 4px 4px 2px 4px;
                border-radius: 8px;
                box-shadow: 0 2px 8px 0 #ffebc6;
                border: 1px solid #ffebc6;
                background-image: linear-gradient(to bottom, #f8edda, #fff);
            }
        }
    }




}
</style>