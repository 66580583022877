<template>
  <div id="rank">
    <div class="container">
      <div class="rank_header">
        <img class="bg_mask" :src="imageUrl + 'assist_bg.png'" />
        <h1>{{ $t(`ib6.eventDescriptions.title2`) }}</h1>
        <EventDetailBtn :href="`${this.$i18n.locale}/rankdetail#section_event_rule`"
          :btnId="`${commonStore.currentEvent}_levelrule_logged`" />
      </div>
      <div class="rank_description">
        <div class="description">
          <p>{{ $t(`ib6.eventRanking.description1`) }}</p>
          <!-- <p>{{ $t(`ib6.eventRanking.top10client`) }}</p> -->
          <p>{{ $t(`ib6.eventRanking.rewardTitle`) }}</p>
        </div>
        <img :src="imageUrl + 'USD_50000.png'" class="rank_img" />
        <EventSatisticTime period="ib6.eventLevel.periodInGold" period2="ib6.eventLevel.period"
          condition="ib6.eventLevel.conditionTimeZone" title="ib6.eventTime.inGoldStatistic"
          title2="ib6.eventTime.OutGoldStatistic" />
        <div class="event_switch">
          <span :id="`${eventTypeList[eventSelectedIndex]}_rankquarter1_logged`"
            :class="(eventSelectedIndex == 0) ? 'arrow_left' : 'arrow_left active'" @click="previousEvent"></span>
          <p>{{ $t(`ib6.eventRanking.${eventTypeList[eventSelectedIndex]}`) }}</p>
          <span :id="`${eventTypeList[eventSelectedIndex]}_rankquarter2_logged`"
            :class="(eventSelectedIndex == eventTypeList.length - 1) ? 'arrow_right' : 'arrow_right active'"
            @click="nextEvent"></span>
        </div>

        <div class="notice">
          <img :src="imageUrl + 'help.svg'" />
          <p class="notice_description" v-html="tablist[activeTab].listDescription"></p>
        </div>
        <div v-if="eventTypeList.length > 0" class="list">
          <div class="list_tab">
            <button class="tab_btn" v-for="(tab, key) in tablist" :key="key" :id="'rank_tab' + key"
              :class="{ active: activeTab === key }" @click="setActiveTab(key); setGTMEvent(tab.btnId)">
              <p>{{ tab.tabTitle }}</p>
            </button>
          </div>
          <table>
            <thead>
              <tr class="list_header">
                <template v-for="(th, index) in tablist" :key="index">
                  <template v-if="activeTab == index">
                    <th>
                      {{ th.tabHeaderTitle1 }}
                    </th>
                    <th>
                      <p>{{ th.tabHeaderTitle2 }}</p>
                    </th>
                    <th>
                      <p>{{ th.tabHeaderTitle3 }}</p>
                    </th>
                  </template>
                </template>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, index) in responseData" :key="index" :class="''">
                <td>
                  <img :class="{ top_img: index < 3, consolation_img: index > 2, no10_img: index > 8 }"
                    :src="imageUrl + `no${item.ranking}.png`" />
                </td>
                <td>
                  <p>{{ item.name }}</p>
                </td>
                <td>
                  <p>{{ formatNumber(Object.keys(item), item[tablist[activeTab].tableContentValue]) }}</p>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div class="rank_condition">
          <p class="update">{{ $t("ib6.eventRanking.update") }}</p>
          <p class="condition">{{ $t("ib6.eventRanking.condition") }}</p>
        </div>

        <EventInfoBtn :btnInfoTitle="$t('ib6.eventRanking.moreInfo')" :href="`${this.$i18n.locale}/rankdetail`"
          :btnId="`${commonStore.currentEvent}_levelrule_logged`" />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import EventSatisticTime from "@/views/ib6/home_event/EventSatisticTime.vue";
import EventInfoBtn from "@/components/button/EventInfoBtn.vue";
import EventDetailBtn from "@/components/button/EventDetailBtn.vue";
import { setGTMEvent } from "@/service/gtm";
import {
  rankingEventInGold,
  rankingTradingVolume,
  rankingMasterIb,
} from "@/modal/Ranking";
import {
  getRanking,
} from "@/api/ib6";
import { useCommonStore } from '@/stores/common'

export default {
  props: {
    eventTypeList: {
      default: []
    },
  },
  data() {
    return {
      imageUrl:
        "https://doo-prime-static.oss-cn-hongkong.aliyuncs.com/ib_event/ib6/images/",
      activeTab: 0,
      eventSelectedIndex: this.eventTypeList.length - 1,
      tablist: [],
      responseData: [],
      selectedRankType: 'TOP10_EVENT_IN_GOLD',
      commonStore: useCommonStore(),
    };
  },
  methods: {
    setGTMEvent,
    // async getRankingEventInGoldList() {
    //   const response = await getRankingEventInGold();
    //   const data: rankingEventInGold[] = response.data.data;
    //   this.responseData = data;
    // },
    // async getRankingTradingVolumeList() {
    //   const response = await getRankingTradingVolume();
    //   const data: rankingTradingVolume[] = response.data.data;
    //   this.responseData = data;
    // },
    async getRankingData(type) {
      const response = await getRanking(type);
      const data = response.data.data;
      this.responseData = data[0].rankings;
    },
    formatNumber(key: string[], number: number) {
      if (typeof number !== 'undefined') {
        if (key.includes("amount")) {
          return String(number).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        }
        else {
          return number.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        }
      }
    },
    setActiveTab(index: number) {
      this.activeTab = index;
      switch (index) {
        case 0:
          this.selectedRankType = 'TOP10_EVENT_IN_GOLD';
          return this.getRankingData(
            {
              rankingTypes: this.selectedRankType,
              eventTypes: this.eventTypeList[this.eventSelectedIndex]
            });
        case 1:
          this.selectedRankType = 'TOP10_TRADING_VOLUME';
          return this.getRankingData({
            rankingTypes: this.selectedRankType,
            eventTypes: this.eventTypeList[this.eventSelectedIndex]
          });
        case 2:
          this.selectedRankType = 'TOP10_MIB';
          return this.getRankingData({
            rankingTypes: this.selectedRankType,
            eventTypes: this.eventTypeList[this.eventSelectedIndex]
          });
        default:
          break;
      }
    },

    previousEvent() {
      if (this.eventSelectedIndex > 0) {
        this.eventSelectedIndex = this.eventSelectedIndex - 1;
        this.updateTablist()
        this.getRankingData({
          rankingTypes: this.selectedRankType,
          eventTypes: this.eventTypeList[this.eventSelectedIndex]
        });
      }
    },
    nextEvent() {
      if (this.eventSelectedIndex < this.eventTypeList.length - 1) {
        this.eventSelectedIndex = this.eventSelectedIndex + 1;
        this.updateTablist()
        this.getRankingData({
          rankingTypes: this.selectedRankType,
          eventTypes: this.eventTypeList[this.eventSelectedIndex]
        });
      }
    },
    updateTablist() {
      this.tablist = [
        {
          listDescription: this.$t("ib6.eventRanking.richDescription"),
          tabTitle: this.$t("ib6.eventRanking.richList"),
          tabHeaderTitle1: "",
          tabHeaderTitle2: this.$t("ib6.eventRanking.name"),
          tabHeaderTitle3: this.$t("ib6.eventRanking.deposit"),
          tableContentValue: "amount",
          btnId: `${this.eventTypeList[this.eventSelectedIndex]}_wealthlist_logged`
        },
        {
          listDescription: this.$t("ib6.eventRanking.topTraderDescription"),
          tabTitle: this.$t("ib6.eventRanking.topTraderList"),
          tabHeaderTitle1: "",
          tabHeaderTitle2: this.$t("ib6.eventRanking.name"),
          tabHeaderTitle3: this.$t(`ib6.eventRanking.${this.eventTypeList[this.eventSelectedIndex]}_volume`),
          tableContentValue: "amount",
          btnId: `${this.eventTypeList[this.eventSelectedIndex]}_toptraderlist_logged`,
        },
        {
          listDescription: this.$t("ib6.eventRanking.expensionDescription"),
          tabTitle: this.$t("ib6.eventRanking.expansionList"),
          tabHeaderTitle1: "",
          tabHeaderTitle2: this.$t("ib6.eventRanking.name"),
          tabHeaderTitle3: this.$t("ib6.eventRanking.newClient"),
          tableContentValue: "amount",
          btnId: `${this.eventTypeList[this.eventSelectedIndex]}_quickimprovedlist_logged`
        },
      ];
    }
  },
  components: {
    EventSatisticTime,
    EventInfoBtn,
    EventDetailBtn,
  },
  mounted() {
    this.getRankingData({
      rankingTypes: this.selectedRankType,
      eventTypes: this.eventTypeList[this.eventSelectedIndex]
    });

  },
  created() {
    this.updateTablist()
  }
};
</script>

<style lang="scss">
$cdn: 'https://doo-prime-static.oss-cn-hongkong.aliyuncs.com/ib_event/ib6/images/';

#rank {

  .bg_mask {
    height: 48px;
    width: 100%;
    position: absolute;
    z-index: -1;
    left: 0;
  }

  .rank_header {
    position: relative;
    z-index: 9;
    height: 68px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px 16px 28px 16px;
    border-radius: 12px;
    box-shadow: 0 2px 8px 0 rgba(151, 151, 151, 0.3);
    border: solid 1px #fff;
    background-image: linear-gradient(6deg,
        #e3cfa1 50%,
        #fff9eb 100%,
        #fff3d8 62%,
        #dfc894 5%);

    p {
      font-size: 14px;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #000;
      margin: 0;
    }

    h1 {
      font-family: AlibabaPuHuiTi;
      font-size: 20px;
      font-weight: 900;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #644415;
      margin: 0;
    }
  }

  .rank_description {
    position: relative;
    z-index: 10;
    margin-top: -20px;
    border-radius: 12px;
    height: 100%;
    width: 100%;
    border: solid 1px #fff;
    background-color: #fff;
    padding: 24px 0;


    .description {
      margin: 0 45px 16px 46px;

      p {
        margin: 0;
        font-size: 14px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: center;
        color: #252525;
      }
    }

    .event_switch {
      padding: 6px 10px;
      margin: 32px 24px 16px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      border: solid 1px rgba(217, 29, 34, 0.15);
      background-image: linear-gradient(to bottom, #f9f9f9, rgba(255, 235, 235, 0.96));

      .greyscale {
        color: #d8d8d8;
      }

      .arrow_left {
        cursor: pointer;
        width: 0;
        height: 0;
        border-top: 5px solid transparent;
        border-right: 10px solid #cbcbcb;
        border-bottom: 5px solid transparent;

        &.active {
          border-right: 10px solid #ee0a24;
        }


      }

      .arrow_right {
        width: 0;
        height: 0;
        border-top: 5px solid transparent;
        border-left: 10px solid #cbcbcb;
        border-bottom: 5px solid transparent;
        cursor: pointer;

        &.active {
          border-left: 10px solid #ee0a24;
        }
      }

      p {
        margin: 0;
        font-size: 14px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: center;
        color: #000;
      }
    }

    .rank_img {
      max-width: 194px;
      height: 40px;
    }

    .satisticTime {
      margin: 35px 22px 32px 23px;
    }

    .notice {
      border-style: solid;
      border-width: 1px;
      border-image-source: linear-gradient(to bottom, #ffebc6 100%, #f2e0bf 4%);
      border-image-slice: 1;
      background-image: linear-gradient(to bottom, #fff5e3, rgba(255, 245, 227, 0));
      display: flex;
      align-items: start;
      padding: 8px;
      margin: 0 24px 24px 24px;

      img {
        width: 26px;
        height: 22px;
        padding-right: 4px;
      }

      .notice_description {
        margin: 0;
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.57;
        letter-spacing: normal;
        text-align: left;
        color: #3a424b;

        span {
          color: #c39654;
        }
      }
    }

    .list {
      .list_tab {
        display: flex;
        justify-content: space-evenly;
        margin: 0 0 8px 0;

        button {
          background: transparent;
          border: none;
          width: 120px;
          height: 20px;
          padding-block: 0;
          padding-inline: 0;

          &:hover {
            cursor: pointer;
          }

          p {
            margin: 0;
            font-size: 14px;
            font-weight: 600;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            text-align: center;
            color: #999;
          }

          &:nth-child(1) {
            width: 84px;
          }
        }

        .active {
          &:after {
            content: "";
            display: block;
            height: 9px;
            width: 100%;
            background-image: url($cdn + "tab_select.png");
            background-repeat: no-repeat;
            background-size: 100% 100%;
          }

          p {
            margin: 0;
            font-size: 14px;
            font-weight: 600;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            text-align: center;
            color: #252525 !important;
          }
        }
      }

      table {
        border-collapse: collapse;
        width: 100%;

        thead {
          height: 33px;
          width: 100%;
          background-image: url($cdn + "list_header.png");
          background-repeat: no-repeat;
          background-size: 130% 130%;
          background-position: center;

          th {
            p {
              margin: 0;
              margin-top: -3px;
              font-size: 12px;
              font-weight: 600;
              font-stretch: normal;
              font-style: normal;
              line-height: normal;
              letter-spacing: normal;
              text-align: left;
              color: #fff;
            }

            &:nth-child(1) {
              width: 38px;
            }

            &:nth-child(2) {
              width: 44%;
            }

            &:nth-child(3) {
              width: 44%;
            }
          }
        }

        tbody {
          tr {
            height: 52px;
            border-bottom: 1px solid #e5e9ec;

            &:nth-child(1) {
              background-image: url($cdn + "top1bg.png");
              background-size: 110% 110%;
              background-position: center;
              background-repeat: no-repeat;
            }

            &:nth-child(2) {
              background-image: url($cdn + "top2bg.png");
              background-size: 110% 110%;
              background-position: center;
              background-repeat: no-repeat;
            }

            &:nth-child(3) {
              background-image: url($cdn + "top3bg.png");
              background-size: 110% 110%;
              background-position: center;
              background-repeat: no-repeat;
            }

            td {
              p {
                margin: 0;
                font-size: 14px;
                font-weight: 600;
                font-stretch: normal;
                font-style: normal;
                line-height: normal;
                letter-spacing: normal;
                text-align: left;
                color: #252525;
              }

              &:nth-child(1) {
                text-align: center;
              }

              .top_img {
                height: 36px;
                width: 36px;
              }

              .consolation_img {
                width: 12px;
                height: 15px;
              }

              .no10_img {
                width: 20px;
                height: 16px;
              }
            }
          }
        }
      }

    }

    .rank_condition {
      text-align: center;
      margin: 16px 0 40px 0;

      p {
        margin: 0;
        font-size: 12px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.83;
        letter-spacing: normal;
        text-align: center;
        color: #979797;
      }
    }
  }
}
</style>
