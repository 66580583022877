<template>
    <div id="agentResult">
        <div class="result">
            <img v-if="eventStatus == 'status2' && resultApiData.nextAgentLevel == 10 || eventStatus == 'status3' && resultApiData.agentLevel == 9"
                class="bg_success" :src="imageUrl + 'bg_success.png'" alt="">
            <img v-if="eventStatus == 'status3' && resultApiData.agentLevel == 0 || eventStatus == 'status4' && resultApiData.agentLevel == 0"
                id="doocnyibofferdefeated_pageview_logged" class="bg_success" :src="imageUrl + 'bg_fail.png'" alt="">
            <!-- title -->
            <p v-if="previousEventStatus == 'status3'" class="last_event_title">{{
                $t('ib6.monthlyStatistics.currentEventEndTitle') }}</p>
            <!-- 已成功挑战最高等级 -->
            <p v-if="eventStatus == 'status2' && resultApiData.nextAgentLevel == 10 || eventStatus == 'status3' && resultApiData.nextAgentLevel == 10 || eventStatus == 'status4' && resultApiData.nextAgentLevel == 10"
                class="title">{{
                    $t('ib6.monthlyStatistics.reachTopLevel') }}</p>
            <!-- fail -->
            <p v-else-if="eventStatus == 'status3' && resultApiData.agentLevel == 0 || eventStatus == 'status4' && resultApiData.agentLevel == 0"
                class="title">
                {{ $t('ib6.monthlyStatistics.fail') }}
            </p>
            <!-- 当前挑战等级及奖励 -->
            <p v-else-if="eventStatus == 'status1' || eventStatus == 'status2'" class="title">{{
                $t('ib6.monthlyStatistics.subTitle')
            }}</p>
            <!-- 已成功挑战 -->
            <p v-else-if="eventStatus == 'status3' || eventStatus == 'status4'" class="title">{{
                $t('ib6.monthlyStatistics.levelAchieve')
            }}</p>
            <img v-if="eventStatus == 'status2' && resultApiData.nextAgentLevel < 10 || eventStatus == 'status3' && resultApiData.agentLevel == 0 || eventStatus == 'status4' && resultApiData.agentLevel == 0"
                class=" bg_level" :src="imageUrl + `LV${resultApiData.nextAgentLevel}.png`" alt="">
            <img v-else-if="eventStatus == 'status2' && resultApiData.nextAgentLevel > 9 || eventStatus == 'status3' && resultApiData.agentLevel > 0 || eventStatus == 'status4' && resultApiData.agentLevel > 0"
                class="bg_level" :src="imageUrl + `LV${resultApiData.agentLevel}.png`" alt="">
            <img class="bg_prize" :src="imageUrl + 'prize.png'" alt="">
            <p class="reward_title">{{ $t('ib6.monthlyStatistics.prizeTitle') }} <span>USD
                    {{ resultApiData.prizeInfo.gifts.giftName }}</span></p>
            <div class="wrapper">
                <ProgressBar :currentValue="resultApiData.qualifiedCustomersNumber"
                    :totalValue="resultApiData.qualifiedCustomersStand" />
                <div>
                    <p>{{ $t('ib6.monthlyStatistics.validCustomer') }}</p>
                    <p>
                        <span>
                            <span class="active">{{ resultApiData.qualifiedCustomersNumber }}</span> / {{
                                resultApiData.qualifiedCustomersStand }}
                        </span>
                    </p>
                </div>

                <ProgressBar :currentValue="resultApiData.eventInGold"
                    :totalValue="resultApiData.prizeInfo.depositStandard" />
                <div>
                    <!-- here -->

                    <p>{{ $t('ib6.monthlyStatistics.topUp') }}<a
                            @click="openModal(); setGTMEvent(`${commonStore.currentEvent}_netdepositstatus_logged`)"
                            :id="`${commonStore.currentEvent}_netdepositstatus_logged`"><img class="bg_help"
                                :src="imageUrl + 'help.svg'"></a></p>
                    <p>
                        <span>
                            <span class="active">{{ resultApiData.eventInGold }}</span> / {{
                                resultApiData.prizeInfo.depositStandard }}
                        </span>
                    </p>
                </div>
                <ProgressBar :currentValue="resultApiData.tradingVolume"
                    :totalValue="resultApiData.prizeInfo.volumeStandard" />
                <div>
                    <p>{{ $t('ib6.monthlyStatistics.trade') }}</p>
                    <p>
                        <span>
                            <span class="active">{{ resultApiData.tradingVolume }}</span> / {{
                                resultApiData.prizeInfo.volumeStandard }}
                        </span>
                    </p>
                </div>
            </div>
            <p class="rewardClaimTitle">{{ $t('ib6.monthlyStatistics.totalClaimTitle') }}{{ resultApiData.totalCommission
            }}</p>
            <div v-if="resultApiData.agentLevel > 0 && eventStatus == 'status2' || resultApiData.agentLevel > 1 && eventStatus == 'status3' || resultApiData.agentLevel > 1 && eventStatus == 'status4'"
                class="gold_wrapper">
                <p class="title">{{ $t('ib6.monthlyStatistics.goldTitle') }}</p>
                <div class="gold_content">
                    <img class="bg_gold" :src="imageUrl + 'gold.png'" alt="">
                    <p>USD {{ resultApiData.goldInfo.goldPrize.giftName }}</p>
                </div>
                <div class="progress-bar">
                    <div class="bar" :style="{
                        width: `${(Number(String(resultApiData.goldAmount).replace(/[^0-9\.-]+/g,
                            '')) / Number(String(resultApiData.goldInfo.goldLot).replace(/[^0-9\.-]+/g, ''))) * 100}%`
                    }
                        ">
                    </div>
                </div>
                <div>
                    <p class="sub_title">{{ $t('ib6.monthlyStatistics.gold') }}</p>
                    <p>
                        <span>
                            <span class="active">{{ resultApiData.goldAmount }}</span> / {{
                                resultApiData.goldInfo.goldLot }}
                        </span>
                    </p>
                </div>
                <p class="sub_content">{{ $t('ib6.monthlyStatistics.goldSubTitle') }}</p>
            </div>
            <p v-if="eventStatus == 'status1' || eventStatus == 'status2' || eventStatus == 'status3' && resultApiData.agentLevel > 0"
                class="sub_content">{{
                    $t('ib6.monthlyStatistics.description') }}</p>
            <p v-if="eventStatus == 'status3' && resultApiData.agentLevel == 0 || eventStatus == 'status4' && resultApiData.agentLevel == 0"
                class="sub_content_fail">{{ $t('ib6.monthlyStatistics.noRewardTitle') }}</p>
        </div>
        <EventTimeTable :event-status="eventStatus" :dateToCount="dateToCount" countDownType="prize" />
        <EventInfoBtn :btn-info-title="$t('ib6.monthlyStatistics.btn_more_info')"
            :btnInfoLink="`${this.$i18n.locale}/levelDetail`" :btn-id="`${commonStore.currentEvent}_leveldetail_logged`" />
        <EventRuleBtn :btnRulePath="`${this.$i18n.locale}/levelDetail#section_event_rule`"
            :btn-id="`${commonStore.currentEvent}_levelrule_logged`" />
    </div>
</template>

<script lang="ts">
import { useAgentStore } from '@/stores/user'
import { useCommonStore } from '@/stores/common'
import { ResultData } from "@/modal/Result";
import { removeNumberWithCommas } from "@/service/general";
import EventTimeTable from "@/views/ib6/EventTimeTable.vue";
import ProgressBar from "@/components/ProgressBar.vue";
import EventInfoBtn from "@/components/button/EventInfoBtn.vue";
import EventRuleBtn from "@/components/button/EventRuleBtn.vue";
import { setGTMEvent } from "@/service/gtm";


export default {
    props: {
        eventStatus: {
            default: "",
        },
        dateToCount: {
            default: '',
        },
        resultApiData: {
            default: {} as ResultData,
        },
        previousEventStatus: {
            default: ''
        },
    },
    components: {
        EventTimeTable,
        ProgressBar,
        EventInfoBtn,
        EventRuleBtn,
    },
    data() {
        return {
            agentStore: useAgentStore(),
            commonStore: useCommonStore(),
            imageUrl: "https://doo-prime-static.oss-cn-hongkong.aliyuncs.com/ib_event/ib6/images/",
            receiveData: [],
            selectedPrizeIndex: -1,
            selectedPrize: [],

        }
    },

    methods: {
        setGTMEvent,
        openModal() {
            // this.IB4Store.setSelectedGift(type, code, name);
            this.commonStore.openModal('DepositInfo')
            // this.commonStore.openModal('PassEventRecord')
            // this.commonStore.openModal('RedeemSuccess2')
        },
        selectOption(giftType: any, giftCode: any, giftName: any, index: any) {
            this.selectedPrizeIndex = index;
            this.selectedPrize = [giftType, giftCode, giftName];
        },
        openRedeem() {
            if (this.selectedPrize.length) {
                this.openModal(...this.selectedPrize)
            }
        }
    },

}
</script>

<style lang="scss">
#agentResult {
    p {
        margin: 0;
    }

    .result {
        padding: 24px 0 0;

        .last_event_title {
            max-width: 410px;
            margin: 0 auto 24px;
            padding: 8px 8px 8px 13px;
            border-style: solid;
            border-width: 1px;
            border-image-source: linear-gradient(to bottom, #ffebc6 100%, #f2e0bf 4%);
            border-image-slice: 1;
            background-image: linear-gradient(to bottom, #fff5e3, rgba(255, 245, 227, 0));
            font-size: 14px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.57;
            letter-spacing: normal;
            text-align: center;
            color: #3a424b;
        }


        .bg_success {
            max-width: 24px;
            margin-bottom: 8px;
        }

        .bg_level {
            display: block;
            max-width: 60px;
            margin: 24px auto 4px;
        }

        .bg_prize {
            display: block;
            max-width: 140px;
            margin: 0 auto 11px;
        }

        .title {
            font-family: MicrosoftYaHeiSemibold;
            font-size: 16px;
            font-weight: 600;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            text-align: center;
            color: #252525;
        }

        .reward_title {
            margin-bottom: 24px;
            font-size: 10px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            text-align: center;
            color: #14142a;

            span {
                font-family: MicrosoftYaHeiSemibold;
                font-size: 20px;
                font-weight: 600;
                font-stretch: normal;
                font-style: normal;
                line-height: 1;
                letter-spacing: normal;
                text-align: center;
                color: #14142a;
            }
        }

        .wrapper {
            div {
                display: flex;
                justify-content: space-between;
                margin-bottom: 16px;

                p {
                    display: flex;
                    font-size: 12px;
                    font-weight: normal;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: 1.67;
                    letter-spacing: normal;
                    text-align: left;
                    color: #252525;

                    .bg_help {
                        width: 20px;
                        height: 20px;
                    }

                    span {
                        font-size: 12px;
                        font-weight: normal;
                        font-stretch: normal;
                        font-style: normal;
                        line-height: 1.67;
                        letter-spacing: normal;
                        text-align: left;
                        color: #878793;

                        .active {
                            color: #ee0a24;
                        }
                    }
                }
            }
        }

        .rewardClaimTitle {
            font-size: 12px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.67;
            letter-spacing: normal;
            text-align: left;
            color: #000;
        }


        .gold_wrapper {
            margin: 40px 0 0;
            padding: 16px 12px;
            border-radius: 6px;
            border: solid 1.5px #cea66c;
            background-color: #fcfdff;

            .title {
                font-size: 12px;
                font-weight: 600;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.67;
                letter-spacing: normal;
                text-align: center;
                color: #252525;
            }


            .gold_content {
                margin: 8px 0 16px 0;
                display: flex;
                justify-content: center;
                align-items: center;

                .bg_gold {
                    max-width: 36px;
                    width: 100%;
                }

                p {
                    font-family: MicrosoftYaHeiBold;
                    font-size: 20px;
                    font-weight: bold;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: 0.9;
                    letter-spacing: normal;
                    text-align: left;
                    color: #c39654;
                }
            }

            .progress-bar {
                height: 6px;
                width: 100%;
                background-color: #f5f5f7;
                border-radius: 20px;
                overflow: hidden;
                position: relative;
                margin-bottom: 8px;

                .bar {
                    width: 0;
                    height: 100%;
                    background-color: #e4be65;
                    transition: width 2s linear;
                    border-radius: 9px;
                }
            }

            div {
                display: flex;
                justify-content: space-between;

                p {
                    font-size: 14px;
                    font-weight: normal;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: 1.43;
                    letter-spacing: normal;
                    text-align: left;
                    color: #252525;

                    span {
                        font-size: 12px;
                        font-weight: normal;
                        font-stretch: normal;
                        font-style: normal;
                        line-height: 1.67;
                        letter-spacing: normal;
                        text-align: left;
                        color: #878793;

                        .active {
                            color: #c39654;
                        }
                    }
                }
            }

            .sub_content {
                text-align: left;
            }

        }

        .sub_content {
            margin: 24px auto 0;
            font-size: 10px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.8;
            letter-spacing: normal;
            text-align: center;
            color: #878793;
        }

        .sub_content_fail {
            margin: 24px auto 0;
            font-family: MicrosoftYaHeiSemibold;
            font-size: 16px;
            font-weight: 600;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.5;
            letter-spacing: normal;
            text-align: center;
            color: #14142a;
        }
    }
}

html[lang="kr"] #agentResult {
    .title {
        font-family: NotoSansBold !important;
    }

    .reward_title {
        span {
            font-family: NotoSansBold !important;
        }
    }

}
</style>