<template>
    <div class="modal-content RewardList">
        <p class="title">{{ $t('ib6.modal.claimRewardTitle') }}</p>

        <template v-for="(value, index) in totalRewardApiData.claimablePrize">
            <div class="content_card">
                <div>
                    <img v-if="value.activityType == 0" :src="imageUrl + 'prize1.png'" alt="">
                    <img v-else-if="value.activityType == 1" :src="imageUrl + 'gold.png'" alt="">
                    <img v-else-if="value.activityType == 2 || value.activityType == 3 || value.activityType == 4"
                        :src="imageUrl + 'ranking.png'" alt="">
                    <div v-if="value.activityType == 0" class="content">
                        <p class="reward_title">{{ $t('ib6.modal.apiActivityGifts') }}</p>
                        <p class="reward_content">{{ $t(`ib6.modal.${value.eventType}`) }}</p>
                    </div>
                    <div v-else-if="value.activityType == 1" class="content">
                        <p class="reward_title">{{ $t('ib6.modal.apiActivityGoldGifts') }}</p>
                        <p class="reward_content">{{ $t(`ib6.modal.${value.eventType}`) }}</p>
                    </div>
                    <div v-else-if="value.activityType == 2" class="content">
                        <p class="reward_title">{{ $t('ib6.modal.apiActivityRankingEventInGold') }}</p>
                        <p class="reward_content">{{ $t(`ib6.modal.${value.eventType}`) }}</p>
                    </div>
                    <div v-else-if="value.activityType == 3" class="content">
                        <p class="reward_title">{{ $t('ib6.modal.apiActivityRankingTradingVolume') }}</p>
                        <p class="reward_content">{{ $t(`ib6.modal.${value.eventType}`) }}</p>
                    </div>
                    <div v-else-if="value.activityType == 4" class="content">
                        <p class="reward_title">{{ $t('ib6.modal.apiActivityRankingMIB') }}</p>
                        <p class="reward_content">{{ $t(`ib6.modal.${value.eventType}`) }}</p>
                    </div>
                </div>
                <p class="amount">USD {{ value.prizeAmountText }}</p>
            </div>
        </template>
        <a id="Redeem_2" class="btn" @click="(isLoading) ? '' : claimPrize()"><span>{{ $t('ib6.modal.claimRewardBtnTitle')
        }}</span></a>
    </div>
</template>

<script lang="ts">
import { claimReward } from '@/api/getResult';
import { RewardClaimRoot } from '@/modal/RewardClaim';
import { TotalRewardData } from '@/modal/TotalReward';
import { numberWithCommas } from '@/service/general';
import { useCommonStore } from '@/stores/common'
export default {
    props: {
        totalRewardApiData: {
            default: {} as TotalRewardData,
        },
        getTotalRewardFunction: {}
    },
    data() {
        return {
            imageUrl: "https://doo-prime-static.oss-cn-hongkong.aliyuncs.com/ib_event/ib6/images/",
            commonStore: useCommonStore(),
            isLoading: false,

        }
    },
    methods: {
        async claimPrize() {
            try {
                this.isLoading = true;
                this.totalRewardApiData.giftTypeKey.claimType = 0
                console.log(this.totalRewardApiData)
                const data: RewardClaimRoot = await claimReward(this.totalRewardApiData.giftTypeKey);
                if (data.code == 0) {
                    this.isLoading = false;
                    await this.getTotalRewardFunction();
                    this.commonStore.openModal('RedeemSuccess2');
                } else {
                    this.isLoading = false;
                    this.commonStore.openModal('RedeemFail2');
                }
            } catch (error) {
                this.isLoading = false;
                this.commonStore.openModal('RedeemFail2');
            }

        }
    },

}
</script>

<style lang="scss">
.modal-content-wrapper {
    padding: 24px 16px !important;
}

.RewardList {

    p {
        margin: 0;
    }

    .title {
        font-size: 16px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: 1px;
        text-align: center;
        color: #252525;
    }


    .content_card {
        margin: 8px 0;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-radius: 8px;
        box-shadow: 0 2px 8px 0 rgba(151, 151, 151, 0.1),
            0 0 0 1px #ffebc6;
        border-image-source: linear-gradient(to bottom, #ffebc6 100%, #f2e0bf 4%);
        border-image-slice: 1;
        background-image: linear-gradient(to bottom, #fff, #fff), linear-gradient(to bottom, #ffebc6 100%, #f2e0bf 4%);
        background-origin: border-box;
        padding: 8px 16px;

        &:first-of-type {
            margin: 24px 0 8px;
        }

        &:last-of-type {
            margin: 8px 0 32px;
        }

        div {
            display: flex;
            justify-content: space-between;
            align-items: center;

            img {
                width: 40px;
                height: 40px;
                object-fit: cover;
            }

            .content {
                display: flex;
                flex-direction: column;
                align-items: start;

                .reward_title {
                    max-width: 105px;
                    margin-left: 9px;
                    font-size: 14px;
                    font-weight: 600;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: normal;
                    letter-spacing: normal;
                    text-align: left;
                    color: #252525;
                    // text-wrap: nowrap;
                }

                .reward_content {
                    margin-left: 9px;
                    font-size: 12px;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: normal;
                    letter-spacing: normal;
                    text-align: left;
                    color: #252525;
                    text-wrap: nowrap;
                }
            }

        }

        .amount {
            font-size: 16px;
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            text-align: right;
            color: #d91d22;
            text-wrap: nowrap;
        }
    }

    .btn {
        display: inline-block;
        max-width: 180px;
        margin: auto;
        border-radius: 21px;
        border-style: solid;
        border-width: 2px;
        border-image-source: linear-gradient(92deg, #f1353a 0%, #ffc1c2 23%, rgba(241, 180, 154, 0.57) 50%, #ffc1c2 72%, #f1353a 97%);
        border-image-slice: 0;
        background-image: linear-gradient(to bottom, #d91d22, #d91d22), linear-gradient(92deg, #f1353a 0%, #ffc1c2 23%, rgba(241, 180, 154, 0.57) 50%, #ffc1c2 72%, #f1353a 97%);
        background-origin: border-box;
        background-clip: content-box, border-box;

        span {
            display: inline-block;
            padding: 7px 16px;
            border-radius: 23px;
            font-size: 16px;
            font-weight: 600;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: 1px;
            text-align: center;
            color: #fff;
        }
    }

}
</style>